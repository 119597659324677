<template>
  <div class="see-model-dia">
    <el-dialog
      title="查看门店"
      :visible="dialogVisible"
      :close-on-click-modal="false"
      width="700px"
      top="3%"
      @close="close"
    >
      <el-form :inline="true" :model="diaStoreForm">
        <el-form-item label="门店名称:">
          <el-input
            placeholder="请输门店名称"
            size="small"
            v-model="diaStoreForm.storeName"
            clearable
          >
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="dialogSearchStore()"
            >查询</el-button
          >
        </el-form-item>
      </el-form>

      <el-table
        ref="companyDia"
        :data="dialogTableData"
        v-loading="diaLoading"
        tooltip-effect="dark"
        max-height="500"
        border
      >
        <el-table-column type="index" align="center" label="序号" width="50" />
        <el-table-column label="门店名称" align="center">
          <template slot-scope="{ row }">
            <el-tooltip
              class="item"
              effect="dark"
              :content="row.name"
              placement="top"
            >
              <div class="nowrap-ellipsis">
                {{ row.name }}
              </div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="100px" v-if="!isSee">
          <template slot-scope="{ row }">
            <el-button type="danger" size="small" @click="delStore(row)"
              >移除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click.native="close">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";

export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    companyId: [String, Number],
    storeIds: {
      type: Array,
      default: () => [],
    },
    isSee: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      diaStoreForm: {
        storeName: "",
      },
      diaLoading: false,
      dialogTableData: [],
    };
  },

  created() {
    this.dialogSearchStore();
  },
  watch: {
    storeIds(){
      this.dialogSearchStore();
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    dialogSearchStore() {
      this.diaLoading = true;
      _api
        .getEnvelopeStoreList({
          ...this.diaStoreForm,
          companyId: this.companyId,
          storeIds: this.storeIds,
          type: "01",
        })
        .then((res) => {
          this.dialogTableData = res.data;
        })
        .finally(() => {
          this.diaLoading = false;
        });
    },
    // 移除操作
    delStore(row) {
      this.$emit("delStore", row.id);
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.see-model-dia {
  .nowrap-ellipsis {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
  .flex-je {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .mb-10 {
    margin-bottom: 10px;
  }
  /deep/.el-form-item {
    margin-bottom: 10px !important;
  }
}
</style>
