<template>
  <div class="activityControls" v-loading="activityInfoLoading">
    <GlobalInfoBar
      icon="custom"
      :title="source === 'edit' ? '编辑红包活动' : '创建红包活动'"
      content=""
    >
      <img class="info-bar_icon" src="@/assets/images/icon_pay_set.png" alt />
    </GlobalInfoBar>
    <GlobalChunk icon="vertical">
      <div>
        <p class="set-title">活动基础信息</p>
        <div class="flex mb-10">
          <img
            src="@/assets/images/ico_ts_red.png"
            style="margin-right: 8px;flex-shrink: 0;width: 18px;height: 18px;"
            alt=""
          />
          <span class="tip-color"
            >创建活动前，请先给门店商户的红包账户余额调账入账，以确保活动可以创建;仅KA商家中的非矩阵商家且开启了到账员工个人账户的商家才能开启活动</span
          >
        </div>
        <div class="flex mb-10">
          <span class="label-S"><span class="label-must">*</span>活动名称</span>
          <el-input
            :disabled="isSee"
            style="width: 40%;"
            size="small"
            type="text"
            placeholder="请输入活动名称"
            v-model.trim="formData.activityName"
            maxlength="8"
            show-word-limit
          >
          </el-input>
        </div>
        <div class="flex mb-10">
          <span class="label-S"><span class="label-must">*</span>活动时间</span>
          <div style="width: 40%;">
            <div class="flex flex-ac">
              <el-date-picker
                :disabled="isSee || formData.isImmediate"
                size="small"
                v-model="activityStartDate"
                :picker-options="pickerOptions"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="开始日期"
                @change="activityStartDateChange"
                >
              </el-date-picker>
              <span style="margin: 0 10px;">至</span>
              <el-date-picker
                :disabled="isSee || !this.activityStartDate"
                size="small"
                v-model="activityEndDate"
                :picker-options="pickerEndOptions"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="结束日期">
              </el-date-picker>
              <div class="raido_inner" v-if="!isSee" @click="isImmediateChange">
                <div :class="formData.isImmediate ? 'active_select' : ''"></div>
                <p>立即生效</p>
              </div>
            </div>
            
            <!-- <el-date-picker
              :disabled="isSee"
              size="small"
              v-model="activityDate"
              :picker-options="pickerOptions"
              value-format="yyyy-MM-dd"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :default-time="['00:00:00', '23:59:59']"
            >
            </el-date-picker> -->
            <p class="txt-666">开始时间和结束时间会在对应日期的凌晨开始生效</p>
          </div>
        </div>
        <div class="flex mb-10">
          <span class="label-S"><span class="label-must">*</span>参与商户</span>
          <div>
            <el-select
              :disabled="isSee"
              class="mr-20"
              size="small"
              v-model="formData.companyId"
              filterable
              placeholder="可输入选择参与门店商户"
              @change="companyChange"
            >
              <el-option
                v-for="item in companyList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
            <el-select :disabled="isSee" class="mr-20" size="small" v-model="formData.storeJoinRange">
              <el-option
                v-for="item in storeTypeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <template v-if="formData.storeJoinRange !== '00'">
              <span class="mr-20"
                >{{ formData.storeJoinRange === "01" ? "已选择" : "已排除"
                }}<span class="txt-underline" @click="openSeeStoreDia">{{storeIds.length}}</span>个门店</span
              >
              <span class="txt-underline" v-if="!isSee" @click="openStoreSelectDia">选择门店</span>
            </template>
            <p class="txt-666" v-show="this.formData.companyId">
              当前门店商户活动红包账户余额为：<span class="tip-color"
                >¥{{ redEnvelopeActivityBalance }}</span
              >
            </p>
          </div>
        </div>
        <div class="flex mb-10">
          <span class="label-S"
            ><span class="label-must">*</span>预计总红包额</span
          >
          <el-input
            :disabled="isSee"
            style="width: 40%;"
            size="small"
            type="text"
            placeholder="输入大于等于0小于等于10000的数，可保留1位小数"
            v-model.trim="formData.expectedTotalAmount"
            @input="validateAllPrice"
            @blur="
              formData.expectedTotalAmount = formData.expectedTotalAmount
                ? parseFloat(formData.expectedTotalAmount)
                : ''
            "
          >
          </el-input>
        </div>
        <div class="flex mb-10">
          <span class="label-S"><span class="label-must">*</span>活动说明</span>
          <template v-if="(source !== 'edit' && source !== 'see') || (formData.activityDescribe)">
            <j-editor
              @handleEditContent="handleEditContent"
              @handleSettingEditContent="handleSettingEditContent"
              type="content"
            ></j-editor>
          </template>
        </div>
      </div>
      <div class="mt-20">
        <p class="set-title">红包信息</p>
        <div class="mb-10 flex-jb flex">
          <div class="flex">
            <img
              src="@/assets/images/ico_ts_red.png"
              style="margin-right: 8px;flex-shrink: 0;width: 18px;height: 18px;"
              alt=""
            />
            <span class="tip-color"
              >说明：满足门槛和奖励条件的订单，将在订单确认成交时自动发放，发放后下单成交人可在“首页-回收红包-我的红包”中领取，领取需在订单对应状态后方可领取，若不领取，订单在收货后自动领取；领取后存入下单成交人的钱包中</span
            >
          </div>
        </div>
        <div class="flex mb-10">
          <span class="label-S">红包领取人</span>
          <p style="line-height: 32px;">
            <span>下单成交人</span
            ><span class="txt-666 ml-20"
              >等级奖励红包默认领取人为下单成交人</span
            >
          </p>
        </div>
        <div class="flex mb-10">
          <span class="label-S"><span class="label-must">*</span>订单指标</span>
          <el-checkbox-group v-model="orderTarget" :disabled="isSee">
            <el-checkbox label="01">用户成交价奖励</el-checkbox>
            <el-checkbox label="02">压价金额奖励</el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="flex mb-10">
          <span class="label-S"><span class="label-must">*</span>订单门槛</span>
          <el-radio-group v-model="formData.orderThreshold" :disabled="isSee">
            <el-radio label="00">无门槛</el-radio>
            <el-radio label="01">有门槛</el-radio>
          </el-radio-group>
        </div>
        <div class="flex mb-10" v-show="orderTarget.includes('01') && formData.orderThreshold === '01'">
          <span class="label-S"
            ><span class="label-must">*</span>单笔用户成交价</span
          >
          <el-select
            :disabled="isSee"
            style="width: 100px;"
            class="mr-20"
            size="small"
            v-model="formData.singleFinalPriceState"
          >
            <el-option label="大于" value="00"></el-option>
            <el-option label="大于等于" value="01"></el-option>
          </el-select>
          <div>
            <div>
              <el-input
                :disabled="isSee"
                style="width: 150px;"
                class="mr-20"
                size="small"
                type="text"
                placeholder="请输入"
                v-model.trim="formData.singleFinalPrice"
                @input="(e) => validatePrice(e, 'singleFinalPrice')"
                @blur="
                  formData.singleFinalPrice =
                    formData.singleFinalPrice &&
                    parseFloat(formData.singleFinalPrice) < 50
                      ? '50'
                      : formData.singleFinalPrice
                      ? parseFloat(formData.singleFinalPrice)
                      : '50'
                "
              >
                <template slot="append">元</template>
              </el-input>
              <span class="txt-666">订单成交价需满足门槛才会进行奖励判定</span>
            </div>
            <p class="tip-color">请输入大于等于50的数，可保留1位小数</p>
          </div>
        </div>
        <div class="flex mb-10" v-show="orderTarget.includes('02') && formData.orderThreshold === '01'">
          <span class="label-S"
            ><span class="label-must">*</span>单笔压价金额</span
          >
          <el-select
            :disabled="isSee"
            style="width: 100px;"
            class="mr-20"
            size="small"
            v-model="formData.singleCutPriceState"
          >
            <el-option label="大于" value="00"></el-option>
            <el-option label="大于等于" value="01"></el-option>
          </el-select>
          <div>
            <div>
              <el-input
                :disabled="isSee"
                style="width: 150px;"
                class="mr-20"
                size="small"
                type="text"
                placeholder="请输入"
                v-model.trim="formData.singleCutPrice"
                @input="(e) => validatePrice(e, 'singleCutPrice')"
                @blur="
                  formData.singleCutPrice = formData.singleCutPrice
                    ? parseFloat(formData.singleCutPrice)
                    : '0'
                "
              >
                <template slot="append">元</template>
              </el-input>
              <span class="txt-666"
                >订单压价金额需满足门槛才会进行奖励判定</span
              >
            </div>
            <p class="tip-color">请输入大于等于0的数，可保留1位小数</p>
          </div>
        </div>
        <div class="flex mb-10">
          <span class="label-S"
            ><span class="label-must">*</span>红包领取条件</span
          >
          <el-radio-group v-model="formData.receiveConditions" :disabled="isSee" class="mr-20">
            <el-radio label="00">订单需发货</el-radio>
            <el-radio label="01">订单需绑码</el-radio>
          </el-radio-group>
          <span class="txt-666" style="line-height: 32px;"
            >用户订单满足奖励条件后，订单需要满足状态后才可以领取红包</span
          >
        </div>
        <div class="flex mb-10">
          <span class="label-S"><span class="label-must">*</span>红包数量</span>
          <el-radio-group v-model="formData.redEnvelopeNumber" :disabled="isSee">
            <el-radio label="00">无数量限制</el-radio>
            <el-radio label="01">限制数量</el-radio>
          </el-radio-group>
        </div>
        <div class="flex mb-10">
          <span class="label-S"
            ><span class="label-must">*</span>发放总额上限</span
          >
          <div>
            <el-input
              :disabled="isSee"
              style="width: 360px;"
              class="mr-20"
              size="small"
              type="text"
              placeholder="不可大于门店活动红包账户余额，可保留1位小数"
              v-model.trim="formData.distributionLimit"
              @input="(e) => validatePrice(e, 'distributionLimit')"
              @blur="
                formData.distributionLimit = formData.distributionLimit
                  ? parseFloat(formData.distributionLimit)
                  : ''
              "
            >
              <template slot="append">元</template>
            </el-input>
            <span class="txt-666" style="line-height: 32px;"
              >到上限时，将会自动关闭活动</span
            >
            <p class="txt-666" v-show="this.formData.companyId">
              当前门店商户活动红包账户余额为：<span class="tip-color"
                >¥{{ redEnvelopeActivityBalance }}</span
              >
            </p>
          </div>
        </div>
        <div class="flex mb-10" v-show="orderTarget.includes('01')">
          <span class="label-S"
            ><span class="label-must">*</span>用户成交价奖励</span
          >
          <div style="width: 60%;">
            <div class="flex-ac flex-jb">
              <p class="mr-20">
                至少添加1个等级的奖励条件,最多可添加10个奖励；<span
                  class="tip-color"
                  >等级从高到低设置，等级与等级之间的区间值需连续，不可重复</span
                >
              </p>
              <el-button size="small" v-if="!isSee" icon="el-icon-plus" type="primary" @click="openSetRedPacketDia('add','01')"
                >新增奖励</el-button
              >
            </div>
            <GlobalTable
              style="margin-top: 10px;"
              ref="ModelSelectTable"
              :columns="makeBargainColumns"
              :data="succPriceRedData"
              :isPagination="false"
              :isIndex="false"
              :maxHeight="400"
            >
              <el-table-column
                label="奖励等级"
                slot="rewardType"
                align="center"
              >
                <template slot-scope="{ $index }">
                  <span>{{ $index + 1 }}等奖励</span>
                </template>
              </el-table-column>
              <el-table-column label="奖励条件" slot="condition" align="center">
                <template slot-scope="{ row }">
                  <span>{{ row.lower }}≤区间＜{{ row.upper }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="红包金额（元）"
                slot="redAmount"
                align="center"
              >
                <template slot-scope="{ row }">
                  <span>{{ row.redAmount }}</span>
                </template>
              </el-table-column>
              <el-table-column
                v-if="formData.redEnvelopeNumber === '01'"
                label="红包数量（个）"
                slot="redCount"
                align="center"
              >
                <template slot-scope="{ row }">
                  <span>{{ row.redCount || 0 }}</span>
                </template>
              </el-table-column>
              <el-table-column label="操作" slot="operation" align="center" v-if="!isSee">
                <template slot-scope="{ row, $index }">
                  <el-button
                    size="small"
                    type="primary"
                    round
                    @click="operationRow(row, 'edit', $index,'01')"
                    >编辑</el-button
                  >
                  <el-button
                    size="small"
                    type="danger"
                    plain
                    round
                    @click="operationRow(row, 'del', $index,'01')"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </GlobalTable>
          </div>
        </div>
        <div class="flex mb-10" v-show="orderTarget.includes('02')">
          <span class="label-S"
            ><span class="label-must">*</span>压价金额奖励</span
          >
          <div style="width: 60%;">
            <div class="flex-ac flex-jb">
              <p class="mr-20">
                至少添加1个等级的奖励条件,最多可添加10个奖励；<span
                  class="tip-color"
                  >等级从高到低设置，等级与等级之间的区间值需连续，不可重复</span
                >
              </p>
              <el-button size="small" v-if="!isSee" icon="el-icon-plus" type="primary"  @click="openSetRedPacketDia('add','02')"
                >新增奖励</el-button
              >
            </div>
            <GlobalTable
              style="margin-top: 10px;"
              ref="ModelSelectTable"
              :columns="makeBargainColumns"
              :data="downPriceRedData"
              :isPagination="false"
              :isIndex="false"
              :maxHeight="400"
            >
            <el-table-column
                label="奖励等级"
                slot="rewardType"
                align="center"
              >
                <template slot-scope="{ $index }">
                  <span>{{ $index + 1 }}等奖励</span>
                </template>
              </el-table-column>
              <el-table-column label="奖励条件" slot="condition" align="center">
                <template slot-scope="{ row }">
                  <span>{{ row.lower }}≤区间＜{{ row.upper }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="红包金额（元）"
                slot="redAmount"
                align="center"
              >
                <template slot-scope="{ row }">
                  <span>{{ row.redAmount }}</span>
                </template>
              </el-table-column>
              <el-table-column
                v-if="formData.redEnvelopeNumber === '01'"
                label="红包数量（个）"
                slot="redCount"
                align="center"
              >
                <template slot-scope="{ row }">
                  <span>{{ row.redCount }}</span>
                </template>
              </el-table-column>
              <el-table-column label="操作" slot="operation" align="center" v-if="!isSee">
                <template slot-scope="{ row, $index }">
                  <el-button
                    size="small"
                    type="primary"
                    round
                    @click="operationRow(row, 'edit', $index,'02')"
                    >编辑</el-button
                  >
                  <el-button
                    size="small"
                    type="danger"
                    plain
                    round
                    @click="operationRow(row, 'del', $index,'02')"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </GlobalTable>
          </div>
        </div>
        <div class="mt-20 flex-ac">
          <el-button @click="goBack">返回</el-button>
          <el-button type="primary" @click="confirmBtn" v-if="!isSee">提交</el-button>
        </div>
      </div>
    </GlobalChunk>
    <el-dialog
      :title="openType == 'add' ? '新增红包' : openType == 'edit' ? '编辑红包' : ''"
      :visible.sync="setRedPacketDiaShow"
      width="600px"
      :close-on-click-modal="false"
      @closed="redPacketDiaClosed"
    >
      <div>
        <div
          style="display: flex;flex-direction: row;align-items: center;color: #FF687B;margin-bottom: 10px;"
        >
          <span>等级从高到低设置，等级与等级之间的区间值需连续，不可重复，起始值≤区间＜结束值；</span>
        </div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="120px"
        >
          <el-form-item label="奖励区间" required>
            <el-col :span="11">
              <el-form-item prop="lower">
                <el-input
                  v-model.number="ruleForm.lower"
                  placeholder="请输入起始值"
                  maxlength="5"
                  @input="priceInputSet('ruleForm','lower')"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col class="line" :span="2" style="text-align: center;"
              >-</el-col
            >
            <el-col :span="11">
              <el-form-item prop="upper">
                <el-input
                  v-model.number="ruleForm.upper"
                  placeholder="请输入结束值"
                  maxlength="5"
                  @input="priceInputSet('ruleForm','upper')"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>
          <el-form-item label="单个红包金额：" prop="redAmount">
            <el-input
              clearable
              v-model="ruleForm.redAmount"
              maxlength="5"
              placeholder="请输入大于0的红包金额，可保留1位小数"
              @input="priceInputSet('ruleForm','redAmount')"
            ><template slot="append">元</template></el-input>
          </el-form-item>
          <el-form-item label="红包数量：" prop="redCount" v-if="formData.redEnvelopeNumber === '01'">
            <el-input
              clearable
              v-model="ruleForm.redCount"
              maxlength="5"
              placeholder="请输入大于0的数"
              @input="priceInputSet('ruleForm','redCount')"
            ><template slot="append">个</template></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="setRedPacketDiaShow = false">取 消</el-button>
        <el-button type="primary" @click="ruleSubmit">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="操作提示"
      :visible.sync="commandShow"
      :close-on-click-modal="false"
      width="480px"
    >
      <div style="margin-bottom: 15px;font-size: 16px;">是否确认{{source === 'edit' ? '编辑' : '创建'}}活动？</div>
      <div class="command">
        <div style="flex-shrink: 0;">谷歌验证码：</div>
        <el-input
          v-model.trim="command"
          placeholder="请输入谷歌动态口令"
          @input="(val) => this.command = val.replace(/[^0-9a-zA-Z]/ig,'')"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="commandShow = false">取消</el-button>
        <el-button type="primary" :loading="commandBtnLoading" @click.native="commandSure">确定</el-button>
      </span>
    </el-dialog>
    <StoreSelectDia v-if="storeSelectDiaShow" :isSee="isSee" :dialogVisible="storeSelectDiaShow" :companyId="formData.companyId" :storeIds="storeIds" @addStore="addStoreSuccess" @close="closeStoreSelectDia" />
    <SeeStoreDia v-if="seeStoreDiaShow" :dialogVisible="seeStoreDiaShow" :companyId="formData.companyId" :storeIds="storeIds" @delStore="delStore" @close="closeSeeStoreDia" />
  </div>
</template>

<script>
import _api from "@/utils/request";
import moment from "moment";
import editor from "./component/editor";
import StoreSelectDia from "./dialog/StoreSelectDia.vue";
import SeeStoreDia from "./dialog/SeeStoreDia.vue";
export default {
  name: "setFeeTemplate",
  components: {
    [editor.name]: editor,
    StoreSelectDia,
    SeeStoreDia
  },
  data() {
    return {
      source: "add",
      activityId: '',
      activityInfoLoading: false,
      redEnvelopeActivityBalance: 0,
      orderTarget: [],
      // activityDate: null,
      activityStartDate: "",
      activityEndDate: "",
      formData: {
        activityName: "",
        companyId: "",
        storeJoinRange: "00",
        expectedTotalAmount: "",
        activityDescribe: "",
        orderThreshold: "00",
        singleFinalPriceState: "00",
        singleFinalPrice: 50,
        singleCutPriceState: "00",
        singleCutPrice: 0,
        receiveConditions: "00",
        redEnvelopeNumber: "00",
        distributionLimit: "",
        isImmediate: false,
      },
      companyList: [],
      storeTypeOptions: [
        {
          label: "全部门店",
          value: "00",
        },
        {
          label: "部分门店",
          value: "01",
        },
        {
          label: "除部分门店",
          value: "02",
        },
      ],
      pickerOptions: {
        disabledDate: (time) => {
          return time.getTime() <= Date.now();
        },
      },
      pickerEndOptions: {
        disabledDate: (time) => {
          if (this.activityStartDate) {
            return time.getTime() <= Date.now() || time.getTime() <= new Date(this.activityStartDate).getTime();
          }
          return time.getTime() <= Date.now();
        },
      },
      succPriceRedData: [],
      downPriceRedData: [],
      makeBargainColumns: [
        { slotName: "rewardType" }, // 奖励等级
        { slotName: "condition" }, // 奖励条件
        { slotName: "redAmount" }, //红包金额（元）
        { slotName: "redCount" }, //红包数量（个）
        { slotName: "operation" },
      ],
      storeIds: [],
      infoId: '',
      storeSelectDiaShow: false,
      seeStoreDiaShow: false,
      //添加/编辑红包
      setRedPacketDiaShow: false,
      openType: 'add',
      setCode: '', // 01:成交价红包；02:压价金额红包
      ruleForm: {
        lower: "",
        upper: "",
        redAmount: "",
        redCount: "",
      },
      rules: {
        lower: [
          { required: true, message: "请输入起始值", trigger: "blur" },
        ],
        upper: [
          { required: true, message: "请输入结束值", trigger: "blur" },
        ],
        redAmount: [
          { required: true, message: "请输入单个红包金额", trigger: "blur" },
          {
            validator: (rule, value, callback) => {
              if (value <= 0) {
                callback(new Error("请输入大于0的数"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        redCount: [
          { required: true, message: "请输入红包数量", trigger: "blur" },
          {
            validator: (rule, value, callback) => {
              if (value <= 0) {
                callback(new Error("请输入大于0的数"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
      },
      commandShow: false, // 谷歌验证码弹框
      commandBtnLoading: false,
      command: "",
      isSee: false,
    };
  },
  created() {
    this.source = this.$route.query.type || "add";
    this.isSee = this.source === 'see'
    this.activityId = this.$route.query.id || "";
    if(this.activityId){
      this.getActivityInfo();
    }
    this.getCompanyList();
  },
  methods: {
    //查看门店红包活动账户余额	
    getCompanyAccount() {
      _api.getCompanyAccount({ companyId: this.formData.companyId }).then((res) => {
        if (res.code === 1) {
          this.redEnvelopeActivityBalance = res.data.companyAccount.redEnvelopeActivityBalance
        }
      })
    },
    getCompanyList() {
      _api.getKACompanyList().then((res) => {
        if (res.code === 1) {
          this.companyList = res.data;
        }
      });
    },
    // 获取活动详情
    getActivityInfo() {
      this.activityInfoLoading = true
      _api.getRedEnvelopeActivityById({id:this.activityId}).then(res => {
        if(res.code === 1){
          console.log(res,'resres');
          for (let key in this.formData) {
            this.formData[key] = res.data[key];
          }
        }
        if(this.source === 'copy'){
          this.formData.activityName = ''
          this.formData.companyId = ''
          this.formData.storeJoinRange = '00'
          this.formData.expectedTotalAmount = ''
          this.formData.activityDescribe = ''
        }else{
          // this.activityDate = res.data.activityStateRegion?res.data.activityStateRegion.split("至") : null;
          this.activityStartDate = res.data.activityStartDate
          this.activityEndDate = res.data.activityEndDate
        }
        this.storeIds = res.data.storeIds || []
        this.succPriceRedData = res.data.finalPriceRegionList || []
        this.downPriceRedData = res.data.cutPriceRegionList || []
        this.orderTarget = []
        if(res.data.finalPriceReward === '01'){
          this.orderTarget.push('01')
        }
        if(res.data.cutPriceReward === '01'){
          this.orderTarget.push('02')
        }
        this.activityInfoLoading = false
        if(this.formData.companyId){
          this.getCompanyAccount()
        }
      })
    },
    activityStartDateChange() {
      this.activityEndDate = ""
    },
    validateAllPrice(e) {
      let value = e;
      // 只允许输入数字和小数点
      value = value.replace(/[^\d.]/g, "");
      // 只允许一个小数点
      value = value.replace(/\.{2,}/g, ".");
      // 第一个字符不能是小数点
      value = value.replace(/^\./g, "");
      // 限制只能输入一位小数
      value = value.replace(/^(\d+)\.(\d).*$/, "$1.$2");
      // 如果输入00，将其转换为0
      if (value === "00") {
        value = "0";
      }
      this.formData.expectedTotalAmount = value > 10000 ? 10000 : value;
    },
    validatePrice(e, type) {
      let value = e;
      // 只允许输入数字和小数点
      value = value.replace(/[^\d.]/g, "");
      // 只允许一个小数点
      value = value.replace(/\.{2,}/g, ".");
      // 第一个字符不能是小数点
      value = value.replace(/^\./g, "");
      // 限制只能输入一位小数
      value = value.replace(/^(\d+)\.(\d).*$/, "$1.$2");
      // 如果输入00，将其转换为0
      if (value === "00") {
        value = "0";
      }
      if (type === "distributionLimit") {
        this.formData.distributionLimit =
          value > parseFloat(this.redEnvelopeActivityBalance) ? this.redEnvelopeActivityBalance : value;
      } else {
        this.formData[type] = value;
      }
    },
    handleEditContent(html) {
      this.formData.activityDescribe = html;
      this.$forceUpdate();
    },
    handleSettingEditContent(set, type) {
      console.log("handleSettingEditContent", this.formData);
      console.log(set);
      console.log(type);
      set.html(this.formData.activityDescribe);
    },
    companyChange() {
      this.getStoreList();
      this.getCompanyAccount()
    },
    getStoreList() {
      _api
        .getStoreSelectList({ companyId: this.formData.companyId })
        .then((res) => {
          this.$store.commit("tagsView/SET_STORELIST", res.data);
        });
    },
    // 打开查看已选门店弹框
    openSeeStoreDia() {
      if(!this.formData.companyId){
        return this.$message.error("请先选择参与商户");
      }
      this.seeStoreDiaShow = true;
    },
    // 关闭查看已选门店弹框
    closeSeeStoreDia() {
      this.seeStoreDiaShow = false;
    },
    delStore(id){
      this.storeIds = this.storeIds.filter(storeId => storeId !== id)
    },
    // 打开选择门店弹框
    openStoreSelectDia() {
      if(!this.formData.companyId){
        return this.$message.error("请先选择参与商户");
      }
      this.storeSelectDiaShow = true;
    },
    // 关闭选择门店弹框
    closeStoreSelectDia() {
      this.storeSelectDiaShow = false;
    },
    // 选择门店弹框确认添加回调
    addStoreSuccess(ids) {
      this.storeIds = this.storeIds.concat(ids)
    },
    
    // 添加/编辑红包弹框关闭回调
    redPacketDiaClosed() {
      if(this.$refs.ruleForm){
        this.$refs.ruleForm.resetFields();
      }
    },
    openSetRedPacketDia(type,code) {
      if((code === '01' && this.succPriceRedData.length === 10) || (code === '02' && this.downPriceRedData.length === 10)){
        return this.$message.error("最多只能添加10个奖励");
      }
      this.openType = type
      this.setCode = code
      this.setRedPacketDiaShow = true
    },
    // 价格段输入校验
    priceInputSet(form,key) {
      if(key === 'redAmount') {
        let value = this[form][key];
        // 只允许输入数字和小数点
        value = value.replace(/[^\d.]/g, "");
        // 只允许一个小数点
        value = value.replace(/\.{2,}/g, ".");
        // 第一个字符不能是小数点
        value = value.replace(/^\./g, "");
        // 限制只能输入一位小数
        value = value.replace(/^(\d+)\.(\d).*$/, "$1.$2");
        // 如果输入00，将其转换为0
        if (value === "00") {
          value = "0";
        }
        this[form][key] = value
      }else{
        this[form][key] = this[form][key].toString().replace(/\D/g, "");
      }
    },
    operationRow(row, type, index, code) {
      if (type == "edit") {
        this.openType = "edit";
        this.setCode = code
        this.ruleForm = JSON.parse(JSON.stringify(row));
        this.ruleForm.index = index;
        this.setRedPacketDiaShow = true;
      }else if(type == "del"){
        if(code === '01') {
          this.succPriceRedData = this.succPriceRedData.filter((item, i) => i !== index);
        }
        if(code === '02') {
          this.downPriceRedData = this.downPriceRedData.filter((item, i) => i !== index);
        }
      }
    },
    ruleSubmit() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          if (!this.ruleForm.lower || !this.ruleForm.upper) {
            this.$message.error("请先补全奖励区间");
            return;
          }
          if (Number(this.ruleForm.lower) >= Number(this.ruleForm.upper)) {
            this.$message.error("奖励区间结束值必须大于起始值");
            return;
          }
          let obj = JSON.parse(JSON.stringify(this.ruleForm));
          obj.lower = Number(obj.lower)
          obj.upper = Number(obj.upper)
          let dataList = this.setCode === '01'?this.succPriceRedData:this.downPriceRedData
          if(dataList.length){
            let isOverlap = false;
            for (let i = 0; i < dataList.length; i++) {
              if(this.openType === 'edit' && i == obj.index){
                continue;
              }
              const item = dataList[i];
              if (
                (obj.lower >= item.lower && obj.lower < item.upper) ||
                (obj.upper > item.lower && obj.upper <= item.upper) ||
                (obj.lower <= item.lower && obj.upper >= item.upper)
              ) {
                isOverlap = true;
                break;
              }
            }
            if (isOverlap) {
              this.$message.error("区间有重复！");
              return;
            }
          }
          if(this.openType === 'add'){
            if(this.setCode === '01'){
              this.succPriceRedData.push(obj);
              this.succPriceRedData.sort(
                (a, b) => Number(b.lower) - Number(a.lower)
              );
            }else{
              this.downPriceRedData.push(obj);
              this.downPriceRedData.sort(
                (a, b) => Number(b.lower) - Number(a.lower)
              );
            }
           
          }else {
            dataList.forEach((item, index) => {
              if (index == obj.index) {
                if(this.setCode === '01'){
                  this.$set(this.succPriceRedData, index, obj);
                  this.succPriceRedData.sort(
                    (a, b) => Number(b.lower) - Number(a.lower)
                  );
                }else{
                  this.$set(this.downPriceRedData, index, obj);
                  this.downPriceRedData.sort(
                    (a, b) => Number(b.lower) - Number(a.lower)
                  );
                }
              }
            });
          }
          this.setRedPacketDiaShow = false
        } else {
          return false;
        }
      });
    },
    // 返回
    goBack() {
      this.$router.go(-1);
    },
    confirmBtn() {
      // 验证表单
      if(!this.formData.activityName){
        return this.$message.error("请输入活动名称");
      }
      // if(!this.activityDate){
      //   return this.$message.error("请选择活动时间");
      // }
      if(!this.activityStartDate){
        return this.$message.error("请选择活动开始日期");
      }
      if(!this.activityEndDate){
        return this.$message.error("请选择活动结束日期");
      }
      if(!this.formData.companyId){
        return this.$message.error("请选择参与商户");
      }
      if(this.formData.storeJoinRange != '00' && !this.storeIds.length){
        return this.$message.error("请添加门店");
      }
      if(this.formData.expectedTotalAmount === ''){
        return this.$message.error("请输入预计总红包额");
      }
      if(!this.formData.activityDescribe){
        return this.$message.error("请输入活动说明");
      }
      if(!this.orderTarget.length){
        return this.$message.error("请选择订单指标");
      }
      if(this.formData.distributionLimit === ''){
        return this.$message.error("请输入发放总额上限");
      }
      if(this.orderTarget.includes('01') && !this.succPriceRedData.length){
        return this.$message.error("请添加用户成交价奖励");
      }
      if(this.orderTarget.includes('02') && !this.downPriceRedData.length){
        return this.$message.error("请添加压价金额奖励");
      }
      this.commandShow = true
    },
    commandSure() {
      if(!this.command){
        return this.$message.error("请输入谷歌验证码");
      }
      this.formData.finalPriceReward = this.orderTarget.includes('01')?'01':'00'
      this.formData.cutPriceReward = this.orderTarget.includes('02')?'01':'00'
      let finalPriceRegionList = JSON.parse(JSON.stringify(this.succPriceRedData));
      let cutPriceRegionList = JSON.parse(JSON.stringify(this.downPriceRedData));
      finalPriceRegionList.forEach((item,index) => {
        item.rewardLevel = index + 1
      })
      cutPriceRegionList.forEach((item,index) => {
        item.rewardLevel = index + 1
      })
      let param = {
        ...this.formData,
        storeIds: this.storeIds,
        // activityStartDate: this.activityDate?this.activityDate[0]:'',
        // activityEndDate: this.activityDate?this.activityDate[1]:'',
        activityStartDate: this.activityStartDate,
        activityEndDate: this.activityEndDate,
        finalPriceRegionList,
        cutPriceRegionList,
        command: this.command,
      }
      this.commandBtnLoading = true
      if(this.source === 'edit'){
        param.id = this.activityId
      }
      let apiMethod = this.source === 'edit'?'editRedEnvelopeActivity':'addRedEnvelopeActivity';
      _api[apiMethod](param).then(res => {
        if(res.code === 1){
          this.$message.success(res.msg || '操作成功')
          this.$router.push({
            path: '/platformOperation/redPacketActivity'
          })
        }
      }).finally(() => {
        this.commandBtnLoading = false;
      });
    },
    // 立即生效
    isImmediateChange() {
      this.formData.isImmediate = !this.formData.isImmediate
      if(this.formData.isImmediate){
        this.activityStartDate = moment().format('YYYY-MM-DD');
      }else{
        this.activityStartDate = '';
        this.activityEndDate = '';
      }
    }
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.activityControls {
  color: #333;
  font-size: 14px;
  .flex {
    display: flex;
  }
  .flex-ac {
    display: flex;
    align-items: center;
  }
  .flex1 {
    flex: 1;
  }
  .flex-jb {
    display: flex;
    justify-content: space-between;
  }
  .flex-je {
    display: flex;
    justify-content: flex-end;
  }
  .flex-jc {
    display: flex;
    justify-content: center;
  }
  .tip-color {
    color: #ff687b;
    font-size: 14px;
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .mb-20 {
    margin-bottom: 20px;
  }

  .mt-20 {
    margin-top: 20px;
  }

  .ml-20 {
    margin-left: 20px;
  }

  .mr-20 {
    margin-right: 20px;
  }

  .mt-10 {
    margin-top: 10px;
  }
  .txt-666 {
    color: #666;
  }
  .txt-underline {
    color: #0981ff;
    text-decoration: underline;
    cursor: pointer;
  }
  .set-title {
    font-weight: bold;
    color: #333333;
    font-size: 16px;
    height: 18px;
    line-height: 18px;
    padding-left: 10px;
    border-left: 3px solid #0981ff;
    margin-bottom: 10px;
  }
  .label-S {
    min-width: 107px;
    text-align: right;
    margin-right: 20px;
    font-size: 14px;
    line-height: 32px;
    color: #666;
    .label-must {
      color: #ff687b;
      margin-right: 4px;
    }
  }
  /deep/.el-checkbox-group {
    display: flex;
    align-items: center;
  }
  /deep/.el-radio-group {
    display: flex;
    align-items: center;
  }
  /deep/.el-input-group__append {
    // border: 1px solid #0981ff;
    // background: #0981ff;
    color: #333;
    padding: 0 10px;
  }
  .command {
    display: flex;
    align-items: center;
  }
  .raido_inner {
    margin-left: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    flex-shrink: 0;
    user-select: none;
    > div {
      border: 1px solid #0981ff;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background: #fff;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #fff;
      }
    }

    > p {
      font-size: 14px;
      line-height: 14px;
      margin-left: 10px;
    }

    .active_select {
      border-color: #0981ff;
    }

    .active_select::after {
      background: #0981ff;
    }
  }
}
</style>
