<template>
  <div class="model-select-dia">
    <el-dialog
      title="选择门店"
      :visible="dialogVisible"
      :close-on-click-modal="false"
      width="1200px"
      top="2%"
      @close="close"
    >
      <div style="display:flex;justify-content: space-between;">
        <div style="width: 660px;margin-right:20px">
          <el-form :inline="true" :model="diaStoreForm">
            <el-form-item label="门店名称:">
              <el-input
                placeholder="请输门店名称"
                size="small"
                v-model="diaStoreForm.storeName"
                clearable
              >
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-button
                size="small"
                type="primary"
                @click="dialogSearchStore()"
                >查询</el-button
              >
            </el-form-item>
          </el-form>
          <el-table
            v-if="dialogVisible"
            ref="addStoreDia"
            :data="dialogTableData"
            v-loading="leftDiaLoading"
            tooltip-effect="dark"
            border
            max-height="515"
            @selection-change="handSelect"
            :row-key="
              (row) => {
                return row.id;
              }
            "
          >
            <el-table-column
              type="selection"
              :reserve-selection="true"
              align="center"
            >
            </el-table-column>
            <el-table-column
              type="index"
              align="center"
              label="序号"
              width="50"
            />
            <el-table-column label="门店名称" align="center">
              <template slot-scope="{ row }">
                <span>{{ row.name }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div style="width: 480px;">
          <p style="margin-bottom:20px">
            已选门店({{ selectedStoreList.length }})
          </p>
          <el-table
            ref="addtipleTable"
            :data="selectedStoreList"
            tooltip-effect="dark"
            border
            max-height="540"
            style="width: 100%"
          >
            <el-table-column
              type="index"
              align="center"
              label="序号"
              width="50"
            >
            </el-table-column>
            <el-table-column label="门店名称" align="center">
              <template slot-scope="{ row }">
                <span>{{ row.name }}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="80px">
              <template slot-scope="{ row }">
                <p
                  @click="detaleSelectMachine(row)"
                  style="color:red;cursor: pointer;text-decoration:underline"
                >
                  删除
                </p>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="close">取消</el-button>
        <el-button type="primary" @click.native="addStoreSubmit"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    companyId: [String, Number],
    storeIds: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      diaStoreForm: {
        storeName: "",
      },
      dialogTableData: [],
      leftDiaLoading: false, //左侧表格loading
      selectedStoreList: [],
    };
  },
  created() {
    this.dialogSearchStore();
  },
  computed: {
  },
  methods: {
    close() {
      this.selectedStoreList = [];
      this.$emit("close");
    },
    dialogSearchStore() {
      this.leftDiaLoading = true;
      _api
        .getEnvelopeStoreList({
          ...this.diaStoreForm,
          companyId: this.companyId,
          storeIds: this.storeIds,
          type: "00",
        })
        .then((res) => {
          this.dialogTableData = res.data;
          this.dialogTableData.forEach((item) => {
            if (
              !this.selectedStoreList.some(
                (selectedItem) => selectedItem.id === item.id
              )
            ) {
              this.$nextTick(() => {
                this.$refs.addStoreDia.toggleRowSelection(item, false);
              });
            }
            if (!this.selectedStoreList.length) {
              this.$refs.addStoreDia.clearSelection();
            }
          });
        })
        .finally(() => {
          this.leftDiaLoading = false;
        });
    },
    handSelect(selection, row) {
      this.selectedStoreList = selection;
    },
    //删除
    detaleSelectMachine(row) {
      let flag = true;
      this.dialogTableData.forEach((item) => {
        if (item.id === row.id) {
          this.flag = false;
          this.$refs.addStoreDia.toggleRowSelection(item, false);
        }
      });
      if (flag) {
        this.selectedStoreList = this.selectedStoreList.filter(
          (item) => item.id !== row.id
        );
      }
    },
    // 添加确认弹框
    addStoreSubmit() {
      if (!this.selectedStoreList.length) {
        this.$message.error("请先选择门店");
        return;
      }
      let storeIds = [];
      this.selectedStoreList.forEach((item) => {
        storeIds.push(item.id);
      });
      this.$emit("addStore", storeIds);
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.nowrap-ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
</style>
